import * as React from 'react';
import { GetGithubUser } from '../Controllers/GithubController';
import { Card, Typography, CardContent, Link, CardActions, Button, TextField, IconButton} from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import { GithubUser } from '../Models/GithubUser';
import { useState } from 'react';


export const GithubView = () => {

    const [user, setUser] = useState(new GithubUser('', '', '', 0));
    const [searchQuery, setSearchQuery] = useState("");

    async function DisplayUser(){
    
        try{
            var prof = await GetGithubUser(searchQuery);
            setUser(prof);
        } catch{
            // Could not find a user with that name
        }
    
    }

    const accountInfo = () => {
        // current user state's account info

        let retVal:string = "";
        retVal.concat(user.id)
    }

    return(
        <>
            <Card>      
                <CardContent sx={{ flexGrow: 1 , display:'flex', flexDirection:'column', padding:'0px'}}>
                    <Typography gutterBottom variant="h5" component="h2" whiteSpace="pre" fontSize="1rem">
                        {user.toString()}
                    </Typography>
            
                </CardContent>
                                   
                <CardActions> 
                    <TextField 
                    onInput={(e)=>setSearchQuery((e.target as any).value)}
                    label = "search by name"
                    placeholder="search..."
                    size='medium'
                    value={searchQuery}
                    />

                    <IconButton type="submit" aria-label="search" onClick={()=>DisplayUser()}>
                        <SearchIcon style={{ fill: "blue" }} />
                    </IconButton>
                </CardActions>
            </Card>
        </>
    )
}