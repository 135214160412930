import { Paper, Typography } from "@mui/material"
import { styled } from "@mui/system"
import { Gallery } from './ImagesGallery'

const AboutMePaper = styled(Paper)`
    height: inherit;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    margin-left: 20px;
    margin-right: 10px;
    min-width: 300px;
    position: relative;
`

export const AboutPage = () => {

    return(
        <>
        <AboutMePaper elevation={6}>
            <div>
                <Typography variant="subtitle1" style={{padding:'10px'}} fontSize= "20px">
                    Nerd who loves soccer. MIGHT COULD STILL GO PRO...
                </Typography>

                <Typography variant="subtitle1" style={{padding:'10px', paddingTop:'0px' }} fontSize= "8px"> 
                    (but writing code to pay the bills)
                </Typography>

                {/* Maybe a values section?  */}

                <Typography variant="subtitle1" style={{padding:'10px', borderBottomStyle:'inset', borderBottom:'black'}}>
                    What makes me tick? ... Solving problems! Whether it's troubleshooting a circuit card or designing a full stack solution for your website.
                </Typography>

                <Typography variant="subtitle1" style={{padding:'10px', paddingLeft:'70px'}}>
                    
                    <br /> How I can help:
                    <br /> - building a static or dynamic webpage
                    <br /> - building a native windows application
                    <br />
                    <br />
                    <br /> With my experience in:
                    <br /> - Node
                    <br /> - Express
                    <br /> - React
                    <br /> - .Net Core
                    <br /> - .Net Framework
                    <br /> 
                    <br /> - Typescript
                    <br /> - Javascript
                    <br /> - C# 
                    <br /> - JSON
                    <br /> - XML
                    <br /> - SQL
    
                </Typography>
                
            </div>
            <Gallery/>

        </AboutMePaper>
        </>
    )
}