import * as React from 'react';



export const Footer = () => {




    return(
        <>
        
        </>
    )
}