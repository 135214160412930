import { useState } from 'react';
import {Box, Container, Typography, Stack} from '@mui/material';
import { styled } from '@mui/system';
import { useEffect } from 'react';

const HiDiv = styled('div')`
    background-color: 'grey';
`

export const Hi = () => {

    const [message, setMessage] = useState(new Array<String>());
    const [str, setStr] = useState("");

    useEffect(() => {
        // callback function to call when event triggers
        const onPageLoad = () => {
          console.log('page loaded');
          // the page is loaded. So then start the function 
            //setIsLoaded(true);
            InitiateTypingEffect();
        };
    
        // Check if the page has already loaded
        if (document.readyState === 'complete') {
          onPageLoad();
        } else {
          window.addEventListener('load', onPageLoad, false);
          // Remove the event listener when component unmounts
          return () => window.removeEventListener('load', onPageLoad);
        }
    }, []); // The empty array indicates that the useEffect doesn't have any dependencies on any state variables. 
            // Therefore, the callback function is only called once the page renders in this case.

    const addNextLetter = (nextLetter:string) =>{
        message.push(nextLetter);
        setMessage(message);
        setStr(message.join("")); // empty string in join method removes seperator
    }


    function InitiateTypingEffect(){

        let currentIndex:number = 0;   
        let previousRandomInt:number = 0;

        const firstLineArr:Array<string> = ['H','i','!',' ',' ','I','m',' ','J','o','h','n',' ',':',')'];

        const getRandomInt = ():number => {
            const min:number = Math.ceil(100); // in milliseconds
            const max:number = Math.floor(300);
            return Math.floor(Math.random() * (max - min) + min);
        }

        
        while(currentIndex<firstLineArr.length){
           
            // first grab the next letter to add
            let nextLetter = firstLineArr[currentIndex]
            // adding the randomint to previous one keeps them in order. Otherwise they might get out of sync
            let randInt = getRandomInt();

            setTimeout(()=>{addNextLetter(nextLetter)},randInt + previousRandomInt)
            currentIndex++;

            // finally update the previous value
            previousRandomInt = randInt + previousRandomInt; 
        } 
    }

    return(
        <>
        
        <Box
        sx={{
        bgcolor: 'whitesmoke',
        pt: 8,
        pb: 6,
        textAlignLast: 'right',
        }}
        >
            <Container maxWidth="sm">
                          
                <Typography
                component="h1"
                variant="h2"
                align="center"
                color="text.primary"
                gutterBottom
                >
                    {str}
                </Typography>

                <Stack direction="row" spacing={2} justifyContent="end">
                    <Typography variant="h6" align="center" color="text.secondary" paragraph sx={{borderBottomStyle:'dashed'}}>
                        software engineer // web developer // sound designer
                    </Typography>
                </Stack>

                <Typography variant="h5" sx={{pt:'30px'}} align="center" color="text.secondary" paragraph>
                {/* I make fun things on the web */}
                I solve problems.
                </Typography>
            </Container>
        </Box>
        
        </>
    )
}