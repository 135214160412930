import ContactForm from './ContactForm';
import { Typography } from '@mui/material';

// onClick={()=>router.back()}    Next hook for getting back to landingpage

export const ContactPage = () => {


    return(
        <div style={{display:'flex', flexDirection:'column', height:'100%', alignItems:'center'}}>
        {/* <ContactForm/> */}
        <Typography variant="h4" style={{padding:'10px', paddingLeft:'70px', position:'absolute', top:'100px'}}>             
            <br /> For business inquiries, contact me at:
            <br />
        </Typography>
        <Typography variant="h6" style={{padding:'10px', paddingLeft:'70px', position:'absolute', top:'150px'}}>             
            <br /> 
            <br /> - johnbrowning.dev@gmail.com
            <br />           
        </Typography>
        </div>

    );
}