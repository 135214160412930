import axios from "axios";
import Joke from "../Models/Joke";
import { Helpers } from "../Helpers";

/**
 * Controller that interacts with the DadJoke API and creates Joke objects 
 */
var key = process.env.REACT_APP_DAD_JOKE_KEY ?? '';
const getJoke = {
  method: 'GET',
  url: 'https://dad-jokes.p.rapidapi.com/random/joke',
  headers: {
    'X-RapidAPI-Key': key,
    'X-RapidAPI-Host': 'dad-jokes.p.rapidapi.com'
  }
};

export async function GetRandomDadJoke():Promise<Joke> {

  let passFlag: boolean = false;
  var joke: Joke = new Joke("default", "default");
    
  while(!passFlag){
    try{
      const response = await axios.request(getJoke);
      joke = new Joke(response.data.body[0].setup, response.data.body[0].punchline, response.data.body[0].id);
    } catch {
    
      joke = new Joke("API Limit reached for the day","...because its the free tier", "limit reached");
    }
    
    let punchline = joke.GetPunchline();
    let setup = joke.GetSetup();

    let helper = new Helpers();
    let filter = helper.FilterBadWords(punchline,setup);

    if(filter === false){
      passFlag = false;
      console.log("Dirty word found, looking for another joke...");
      
    } else { passFlag = true;}
  }
  
  return joke;
}