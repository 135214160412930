export class GithubUser{

    public id:string= "";
    public name:string= "";
    public url:string= "";
    public followers:number = 0;

    constructor(id:string, name:string, url:string, followers:number){ // etc. will have more params
        this.id = id;
        this.name = name;
        this.url = url;
        this.followers = followers;
    }

    public toString = () : string => {
        return(
        `
        id:             ${this.id} 
        name:        ${this.name} 
        followers:   ${this.followers} 
        url:             ${this.url}
        `
        );
    }
}