class Joke {

private punchline: string = " ";
private setup: string = " ";
private id: string = " ";

constructor(setup:string, punchline:string, id:string = " "){
    
    this.setup = setup;
    this.punchline = punchline;
    this.id = id;
}

// getters
public GetSetup() {return this.setup;}
public GetPunchline() {return this.punchline;}
public GetId() {return this.id;}

// setters
public SetSetup(text:string) {this.setup = text;}
public SetPunchline(text:string) {this.punchline = text;}
public SetId(id:string) {this.id = id;}

// Methods

}

export default Joke;