import { Button, Typography } from '@mui/material';
import { useState } from 'react';
import { GetRandomDadJoke } from '../Controllers/JokeController';
import Joke from '../Models/Joke';
import styled from '@emotion/styled';

export const JokeView = () => {

    // todo: make this buttons background change on hover
    // todo: Make this JokeView a project that can be interacted with. Like a GenericProject that inherits Project. Similar to a GithubProject and also shows up on the CardView but implements IProject in a diff way.

    // this is a css button
    const HoverButton = styled.button<{isHovering:boolean}>`
        width: 130px;
        height: 30px;
        background-color: ${(props)=>props.isHovering ? 'red' : 'black'};
    
        display: block;
        margin: auto;
        color: white;
        border-radius: 10px;
        border-color: red;
        place-self: center;
    `

    const [displayedSetup, setDisplayedSetup] = useState(" ");
    const [displayedPunchline, setDisplayedPunchline] = useState(" ");
    const [currentJoke, setCurrentJoke] = useState(DisplayNewJoke);
    const [isHovering, setIsHovering] = useState(false);

    async function DisplayNewJoke():Promise<Joke>{
        const dadJoke = await GetRandomDadJoke();
        setDisplayedSetup(dadJoke.GetSetup());
        setDisplayedPunchline(" ");
        
        // This is a typical Promise constructor using Joke as the type. If resolved use .then , if rejected use .catch
        // there must be a better way to do this
        const prom = new Promise<Joke>((resolve,reject)=>{
            resolve(dadJoke);
        });
        setCurrentJoke(prom);
    
        return dadJoke;
    }

    async function HoverButtonActive(){
        setIsHovering(true); 
        setDisplayedPunchline((await currentJoke).GetPunchline());
    }



    return(
        <>
            <div style={{border:'black', borderRadius:'5px', borderStyle:'groove', height:'-webkit-fill-available', display:'flex', flexDirection:'column', justifyContent:'space-between', marginTop:'20px'}}>
                    <Typography variant="h5" margin="5px" align="center" color="text.secondary" paragraph>
                        {displayedSetup}
                    </Typography>
                
                    <div >
                        <Typography variant="h5" margin="5px" align="center" color="text.secondary" paragraph>
                        {displayedPunchline}
                        </Typography>
                    </div>
                    
                    <div>
                        <HoverButton id= "punchButton" isHovering={isHovering} onMouseEnter={()=>HoverButtonActive()} onMouseLeave={()=>setIsHovering(false)}> 
                            Hover to reveal
                        </HoverButton>
                        <Button sx={{placeSelf:'start'}} onClick={()=>DisplayNewJoke()}>
                        Want Another?
                        </Button>
                    </div>
                    

            </div>

        </>
    )
}

