import * as React from 'react';
import './App.css';
import { LandingPage } from './Views/LandingPage';

function App(): JSX.Element {
 

  return (
    <LandingPage/>
  );
}

export default App;
