import { IProject } from "../Interfaces/IProject";

export class GeneralProject implements IProject{
    
    id: string;
    name: string;
    likes: number;
    dislikes: number;
    link: string;
    views: number;
    description: string;
    size: number;


    // C# like property
    // maybe make 'any' a JSX class or something? Because this is where I will store the Views like JokeView
    // also to do: look up implementing interfaces in JS / TS and why.
    // look up example of decoupling. I still don't totally understand it. and maybe an example with MVC pattern.
    private _view : JSX.Element;
    public get view() : JSX.Element {
        return this._view;
    }
    public set view(v : JSX.Element) {
        this._view = v;
    }
    

    constructor(id:string, name: string, description: string, view:JSX.Element, size: string, likes?: number, dislikes?: number, link?: string, views?: number){

        this.id = id;
        this.name = name;
        this.description = description;
        
        switch(size) {
            case "small":
                this.size = 4;
                break;
            case "medium":
                this.size = 8;
                break;
            case "large":
                this.size = 12;
                break;  
            default:
                this.size = 4;
                break;
          } 

        likes === undefined ? this.likes = 0 : this.likes = likes;
        dislikes === undefined ? this.dislikes = 0 : this.dislikes = dislikes;
        link === undefined ? this.link = "" : this.link = link;
        views === undefined ? this.views = 0 : this.views = views;

        this._view = view;
    }
}