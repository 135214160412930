import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import styled from '@emotion/styled';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { IProject } from '../Interfaces/IProject';
import { GeneralProject } from '../Models/GeneralProject';
import { JokeView } from './JokeView';
import { GithubProject } from '../Models/GithubProject';
import { GithubView } from './GithubView';


const CustomCard = styled(Card)<{size:number}>`
  height: 100%;
  display: flex;
  flex-direction: column;
  height: ${(props) => props.size*4 + 'px'};
`;

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://johnbrowning.dev/">
        johnbrowning.dev
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

//todo: make a ProjectController that does the instantiation of the Projects
const projects:IProject[] = [
  new GeneralProject("1", "Dad Joke API", "*jokes do not reflect my personal views in ANY way", <JokeView/>, 'large', 0,0,'https://dadjokes.io/'), 
  new GithubProject("2", "Test", "Enter a description of project here", <></>, 'small'),
  new GithubProject("3", "Github API", "Find your Github stats here!", <GithubView/>, 'medium', 0,0,'https://github.com/')
] 

const theme = createTheme();

//export const CardView:FunctionComponent = () => { // If I need props in here, use FunctionComponent<Props>
export function CardView():JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main style={{display: 'flex', flexDirection: 'column'}}>
        <Container sx={{ py: 8, height:1500 }} maxWidth="md">
          <Grid container spacing={4} rowSpacing={4}>
            {projects.map((project) => (
              <Grid item key={project.id} xs={project.size}>
                <CustomCard
                  size={project.size *10}
                >
                  
                  <CardContent sx={{ flexGrow: 1 , display:'flex', flexDirection:'column'}}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {project.name}
                    </Typography>
                    <Link style={{cursor:'pointer', alignSelf:'start'}} href={project.link}>{project.link}</Link>
                    <Typography>
                      {project.description}
                    </Typography>
                    {project.view}
                  </CardContent>
                  
                  {project instanceof GeneralProject || project instanceof GithubProject ? // The projects themselves should know whether they have the buttons or not. I should not have to have this logic here.
                    <></> 
                    : 
                    <CardActions> 
                      <Button size="small">View</Button>
                      <Button size="small">Edit</Button>
                    </CardActions>
                  }
            
                </CustomCard>
              </Grid>
            ))}
          </Grid>
        </Container>

        {/* Footer */}
        <Box sx={{ bgcolor: 'background.paper', width: '-webkit-fill-available', position:'sticky', bottom:0, height:'55px', zIndex:'1'}} component="footer">
        
          <Typography
            variant="subtitle1"
            align="center"
            color="text.secondary"
            component="p"
          >
            /Little by little. Bit by bit.\
          </Typography>
          <Copyright />
        </Box>
        {/* End footer */}


      </main>
    </ThemeProvider>
  );
}