import { JsonObjectExpression } from "typescript";
import { GithubUser } from "../Models/GithubUser";

// Maybe I should use a Factory class to new up classes within Controllers.

async function getUser(username:string){
  return fetch(`https://api.github.com/users/${username}`)
  .then(response => response.json())
  .then(response => {
      return response;
  })
}

export async function GetGithubUser(name:string):Promise<GithubUser>{

    var userProf = await getUser(name);

    let id:string = userProf.id;
    let url:string = userProf.url;
    let profName:string = userProf.login;
    let followers: number = userProf.followers;

    var user:GithubUser = new GithubUser(id, profName, url, followers);
    return user;

}
