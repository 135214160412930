import { IProject } from "../Interfaces/IProject";

export class GithubProject implements IProject{
    
    id: string;
    name: string;
    likes: number;
    dislikes: number;
    link: string;
    views: number;
    description: string;
    view: JSX.Element;
    size: number;

    constructor(id:string, name: string, description: string, view: JSX.Element, size: string, likes?: number, dislikes?: number, link?: string, views?: number){

        this.id = id;
        this.name = name;
        this.description = description;
        this.view = view;
        
        switch(size) {
            case "small":
                this.size = 4;
                break;
            case "medium":
                this.size = 8;
                break;
            case "large":
                this.size = 12;
                break;  
            default:
                this.size = 4;
                break;
          } 

        likes === undefined ? this.likes = 0 : this.likes = likes;
        dislikes === undefined ? this.dislikes = 0 : this.dislikes = dislikes;
        link === undefined ? this.link = "" : this.link = link;
        views === undefined ? this.views = 0 : this.views = views;
    
    }
    
    // To-Do:
    // Check for a github api and tap into that if so

}