import { Filter } from 'helper-functions/WordFilter'
export class Helpers {
  constructor () {
    return this
  }

  FilterBadWords = function (punchline, setup) {
    let retVal = false
    retVal = Filter(punchline, setup)
    return retVal
  }
}
